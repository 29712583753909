.document-library {
  .category-card {
    background-color: #f4f1de;
    margin-bottom: 30px;
    padding: 20px 20px;

    .heading {
      margin: 10px 0 20px 0;
      text-align: center;
    }

    .description {
      text-align: center;
    }

    .view-resources {
      margin: 20px 0 10px 0;
      text-align: center;
    }
  }
}
