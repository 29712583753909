/*
  .company-list {
    .company {
      border-bottom: 1px solid $border-color-light;
      margin-bottom: 20px;
      padding-bottom: 20px;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 20px;
      }
    }
  }
*/
