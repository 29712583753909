.order-processing-wrapper {
  p.text-w-line-break {
    white-space: pre-line;
  }

  table {
    margin-top: 10px;
    margin-bottom: 0;

    thead {
      th {
        border-bottom-width: 1px;

        &.no-bottom-border {
          border-bottom-width: 0;
        }
      }
    }

    th, td {
      padding: 10px 25px 10px 5px;
    }
  }

  .inline-input {
    margin: 5px 0;
    label {
      padding-left: 0;
      min-width: 150px;
    }
  }

  .new-flag {
    background-color: #c3e6cb;
    color: #155724;
    border-radius: .25em;
    font-size: .8em;
    margin-left: 15px;
    padding: 4px 10px;

    &:after {
      content: 'New';
      clear: both;
    }
  }
}
