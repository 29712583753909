.hide, .hidden {
  display: none;
}

//
// Lists
//
ul {
  &.sortable-list {
    list-style-type: none;
    padding-inline-start: 0;

    li {
      clear: both;
      padding-bottom: 20px;
      padding-left: 1.3em;

      &:before {
        content: "\f0c9";
        display: inline;
        font-family: FontAwesome;
        margin-left: -1.3em;
        margin-right: 1.0em;
        width: 1.3em;
      }
    }
  }

  &.nav-tabs {
    .nav-link {
      &.orange-bg {
        background-color: $orange;
        color: $white;
        font-weight: $font-weight-bold;

        &:after {
          content: '\f0da';
          font-family: FontAwesome;
          margin-left: 5px;
        }
      }
    }

    @media (max-width: 576px) {
      .nav-item {
        width: 100%;
      }

      .nav-link {
        border: $nav-tabs-border-width solid $nav-tabs-border-color;

        &.orange-bg {
          border: none;
        }
      }
    }
  }
}

//
// Buttons
//
.btn-danger {
  color: #fff !important;
}


//
// Links
//
a {
  color: $medium-orange;

  &.disabled {
    opacity: 0.65;
    pointer-events: none;
  }

  &:hover {
    color: $dk-orange;
  }
}


//
// Sortable
//
.ui-sortable {

  .ui-sortable-handle {
    &:before {
      background-color: $medium-orange;
      color: $white;
      content: "\f047";
      display: inline;
      float: left;
      font-family: FontAwesome;
      padding: 5px 4px;
    }

    .form-control {
      border-left: none;
      width: calc(100% - 28px);
      width: -moz-calc(100% - 28px);
      width: -webkit-calc(100% - 28px);
    }
  }
}

table.sortable-table {
  th.sort-col, td.sort-col {
    min-width: 48px;
    max-width: 48px;
  }
}


//
// Accordion
//
.accordion {
  .accordion-icon {
    color: #ec881e;
    display: inline-block;
    float: left;
    font-size: 16px;
    line-height: 16px;
    margin-top: 1px;

    &:before {
      content: "\f150";
      display: inline-block;
      font-family: FontAwesome;
    }

    &.collapsed {
      &:before {
        content: "\f152";
        display: inline-block;
        font-family: FontAwesome;
      }
    }
  }

  .accordion-header {
    padding-left: 20px;
  }

  .additional-content {
    padding-left: 20px;
  }
}


//
// Tables
//
table,
.table {
  border-top: none;

  thead {
    th {
      border-top: none;
      border-bottom: 4px solid #747474;
    }
  }

  th {
    border-top: none;
    color: #4d4d4d;
    font-size: 15px;
    line-height: 20px;
    font-weight: $font-weight-light;
    text-transform: uppercase;
  }

  td {
    border: none;
  }

  th, td {
    padding: 10px 20px 10px 10px;
    //white-space: nowrap;
    vertical-align: middle;

    &.sticky {
      position: sticky;
      left: 0px;
      background: $white;
    }

    &.colored-bg {
      background-color: rgba(0,0,0,.03);
    }
  }

  tr {
    td {
      border-bottom: 1px solid #d2d2d2;

      .sort-handle {
        width: 20px;
      }
    }

    &.no-border-bottom {
      td {
        border-bottom: none;
        padding-bottom: 0px;
      }
    }

    &.colored-bg {
      td {
        background-color: rgba(0,0,0,.03);
      }
    }


    &:last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  &.table-hover {
    tr {
      th, td {
        &.sticky {
          background-color: $white;
        }
      }
    }

    tr:hover {
      td {
        &.sticky {
          background-color: rgba(0,0,0,.03);
        }
      }
    }
  }

  &.align-top {
    tr {
      td {
        vertical-align: top;
      }
    }
  }
}



//
// Flash Messages
//
.alert {
  &.fixed-alert {
    position: fixed;
    right: 25px;
    bottom: 25px;
    z-index: 999;
  }
}


//
// Favorite
//
.favorite {
  //display: inline-block;
  //padding-left: 15px;

  .btn {
    text-decoration: none;
    color: $medium-orange;
    padding: 0;

    &.btn-favorited {
      &:before {
        content: '\f005';
        font-family: 'FontAwesome';
      }
    }

    &.btn-not-favorited {
      &:before {
        content: '\f006';
        font-family: 'FontAwesome';
      }
    }

    &:hover, &:active {
      text-decoration: none;
      color: $dk-orange;
    }
  }
}


//
// Popover
//
.popover {
  &.popover-error {
    border: 0;
    border-radius: 0;

    .arrow {
      &:after {
        border-left-color: $error-text-background-border;
        border-right-color: $error-text-background-border;
      }
    }

    .popover-header {
      background-color: $error-text-background-border;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      color: $white;
      font-weight: $font-weight-bold;
    }

    .popover-body {
      background-color: $error-text-background-border;
      color: $white;

      p, ul {
        margin-bottom: .5rem;
      }
    }
  }
}

//
// Switch
//

.custom-switch {
  .custom-control-label {
    &:before {
      background-color: #be5d48;
    }

    &:after {
      background-color: $error-text-background-highlight;
    }
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &:after {
          background-color: #155724;
        }
      }
    }
  }
}

.custom-control-input {
  &:checked {
    ~ .custom-control-label {
      &:before {
        background-color: #d4edda;
        border-color: #155724;
      }
    }
  }
}
