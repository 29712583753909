/**
 *
 * Estimate form page styles
 *
**/
.estimate-page {
  .estimate-widget {
    .min-width-field {
      min-width: 120px;
    }
  }
}
