/**
 *
 * Navbar Styles
 *
 */
.navbar {
  padding: 0 1rem;
  &.bg-dark {
    background-color: $navbar-background-color !important;
  }
}

.navbar-dark {
  .navbar-nav {

    .nav-item {
      &.show {
        background-color: $navbar-dropdown-background-color;
      }
    }

    .nav-link {
      color: $navbar-text-color;
      font-size: 15px;
      font-weight: $font-weight-bold;
      line-height: 20px;
      padding: 18px 8px;

      &:hover {
        color: $navbar-text-hover-color;
        background-color: $navbar-dropdown-background-color;
      }
    }
  }
}

.navbar-expand-lg {
  .navbar-nav {
    .dropdown-menu {
      background-color: $navbar-dropdown-background-color;
      color: $navbar-dropdown-text-color;
    }
  }
}

.dropdown {
  &:focus {
    outline: none;
  }
}

.dropdown-menu {
  border: none;
  padding: 0 16px;
  top: 54px;
  left: auto;
  right: 0;
}

.dropdown-item {
  border-bottom: 1px solid #c4c4c4;
  color: $navbar-dropdown-text-color;
  font-size: 14px;
  font-weight: $font-weight-bold;
  line-height: 19px;
  padding: 8px 0;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: $navbar-dropdown-background-color;
    color: $navbar-dropdown-text-color-hover;
  }
}


/**
 *
 * Sidebar Styles
 *
 */
#sidebar.PROD {
  background: #8c8c8b;
}
#sidebar.QA {
  background: #e77049;
}
#sidebar.DEV, #sidebar.LOCAL_DEV {
  background: #000000;
}

#sidebar {
  position: fixed;
  height: 100%;
  font-size: 1.8rem;
  line-height: 2.3rem;
  min-width: 200px;
  max-width: 200px;
  padding: 0 20px;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  z-index: 999;

  header {
    font-size: 20px;
    line-height: 56px;

    a {
      background: url("../images/lutek-logo-white-300x112.png");
      background-repeat: no-repeat;
      background-size: 150px auto;
      color: #fff;
      display: block;
      margin: 15px 0;
      text-decoration: none;

      &:hover {
        color: #fff;
      }
    }

    img {
      width: 170px;
      height: auto;
    }
  }

  .icon {
    background: none;
    color: $navbar-text-color;
    display: block;
    font-size: 17px;
    font-weight: $font-weight-bold;
    line-height: 23px;
    padding: 10px 0;
    white-space: nowrap;

    &:before {
      margin-right: 10px;
      font-family: 'FontAwesome';
      font-weight: $font-weight-normal;
    }

    &.dashboard {
      &:before {
        content: '\f108';
      }
    }

    &.orders {
      &:before {
        content: '\f0f6';
      }
    }

    &.company {
      &:before {
        content: '\f114';
      }
    }

    &.admin {
      &:before {
        content: '\f013';
      }
    }

    &.user {
      &:before {
        content: '\f007';
      }
    }

    &.logout {
      &:before {
        content: '\f090';
      }
    }

    &.toggle-nav {
      &:before {
        content: '\f190';
      }
    }

    &:hover {
      background: none;
      color: $navbar-text-hover-color;
      text-decoration: none;
    }
  }

  .nav {
    padding-top: 10px;

    li {
      display: block;
      width: 100%;
    }
  }

  .user-nav {
    position: absolute;
    bottom: 25px;
  }

  &.minimized {
    min-width: 80px;
    max-width: 80px;
    padding: 0 20px;

    header {
      a {
        background: url("../images/lutek-logo-mark-white-112x112.png");
        background-repeat: no-repeat;
        background-size: 40px auto;
      }
    }

    .icon {
      text-align: center;

      span {
        display: none;
      }

      &:before {
        margin-right: 0;
      }

      &.toggle-nav {
        &:before {
          content: '\f18e';
        }
      }
    }
  }
}

/*
 *
 * Utility Navigation
 *  --Top of page - breadcrumbs and search bar
 *
 */


// Breadcrumbs
.breadcrumb {
  //background-color: rgba(0, 0, 0, 0.03);
  background-color: $white;
  padding-left: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 0;

  .breadcrumb-item {
    font-size: 18px;
    font-weight: $font-weight-light;
  }
}


.utility-nav {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 16px;

  .breadcrumb-container {
    //padding: 0.5rem !important;
  }

  .search-container {
    position: relative;

    .wrapper {
      display: inline-block;
      position: relative;
      width: 100%;

      .search-input {
        padding-left: 30px;
      }

      &:before {
        position: absolute;
        content: '\f002';
        color: $medium-orange;
        font-family: 'FontAwesome';
        left: 10px;
        top: 5px;
      }
    }

    .search-results {
      position: absolute;
      right: 0;
      background-color: $white;
      border: 1px solid $form-input-border-color;
      max-width: 300px;
      min-width: 100%;
      width: 300px;
      margin-top: -1px;
      z-index: 1001;

      .result {
        .record {
          border-bottom: 1px solid $form-input-border-color;
          display: block;
          margin: 0px 10px;
          padding: 10px 0;

          .record-type {
            color: $black;
            font-weight: $font-weight-bold;
          }

          .record-text {
            color: $black;
            font-weight: $font-weight-normal;
          }

          &:hover, &:active {
            font-style: italic;
            text-decoration: none;
          }
        }

        &.no-results {
          font-style: italic;
        }

        &:last-child {
          .record {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .utility-nav {
    //flex-direction: column;
    flex-direction: column-reverse !important;
    margin-bottom: 0;

    .breadcrumb-container {
      flex: 100%;
      margin-bottom: 16px;
    }

    .search-container {
      flex: 100%;
      margin-bottom: 16px;
    }
  }
}
