.project-list {
  .project-line-item {
    border-bottom: 1px solid $border-color-light;
    margin-bottom: 10px;
    padding-bottom: 10px;

    &.last {
      border-bottom: none;
    }

    h2 {
      color: #4d4d4d;
    }
  }

  [class~='project-line-item']:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.record-list-items {
  &.project-order-list {
    .item {
      h2 {
        .new-flag {
          background-color: #c3e6cb;
          color: #155724;
          border-radius: .25em;
          font-size: .6em;
          margin-left: 15px;
          padding: 4px 10px;
          vertical-align: top !important;

          &:after {
            content: 'New';
            clear: both;
          }
        }
      }

      .shipping-text {
        span.title {
          font-weight: $font-weight-normal;
          font-size: 1em;
        }
        font-weight: $font-weight-light;
        font-size: .90em;
        margin-bottom: 0;

        &.date-submitted {}
        &.estimated-ship-date {}
        &.estimated-shipping-notes {}
      }

      &.last {
        border-bottom: none;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }
  }
}


//
// Project Estimate List
//
.project-estimate-list {
  .estimate-line-item {
    border-bottom: 1px solid $border-color-light;
    margin-bottom: 15px;
    padding-bottom: 15px;

    h2 {
      color: #4d4d4d;
      clear: both;

      &:after {
        clear: both;
      }
    }

    p {
      &.smaller-text {
        font-size: .9em;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:nth-last-child(2) {
      border-bottom: none;
    }

    &:nth-last-child(1) {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

  }
}


//
// Project Equipment list/options (on Show Project Page)
//
.project-equipment-list {
  .equipment-option {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 25px;
    }
  }

  .equipment-option-quantity {
    margin-bottom: 10px;
  }
}


//
// Field Measure Checklist Options (on Show Project Page)
//
.fm-checklist {
  .fm-checklist-row {
    border-bottom: 1px solid $border-color-light;
    margin-bottom: 10px;
    padding-bottom: 10px;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 20px;
    }
  }

  .fm-question {
    margin-bottom: 0;

    p {
      margin-bottom: 0;
    }
  }

  .fm-options {
    margin-bottom: 0;
  }
}

//
// Project Estimates Sections
//

//Shade Unit Defaults
.shade-unit-config {

}


//
// Takeoff Tab
//
#take-off {
  .btn-add-shade-group {
    background-color: $medium-orange;
    color: $white;
    font-size: 1.0em;

    &:before {
      content: "\f067";
      display: inline;
      font-family: FontAwesome;
      margin-right: 0.5em;
    }

    &:hover, &:active {
      background-color: $dk-orange;
    }
  }
}


//
// Shade Groups / Shade Line Items
//
.shade-groups {
  display: flex; //NEW
  flex-direction: column; //NEW

  .shade-group {
    align-items: stretch !important;
    display: flex !important;
    margin-bottom: 0;
    //overflow: hidden; //NEW
    padding: 0;
    position: relative;

    .add-coupled-shade {
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translate(-50%, 0%);
      display: none;

      a.coupled-shade-btn {
        background-color: rgba(236, 136, 30, .9);
        padding: 5px 10px;
        color: #fff;
        border-radius: 50px;
        font-size: .95em;

        &:hover, &:active {
          cursor: pointer;
        }
      }
    }

    &:hover, &:active {
      .add-coupled-shade {
        display: block;
        z-index: 900;
      }
    }

    > .sort-handle-wrapper {
        display: flex; //NEW
        flex-direction: column; //NEW
        width: 1.5em; //NEW

      > .sort-handle {
        display: flex;
        flex: 1;
        //flex-direction: column; //NEW
        width: 1.5em;
      }

      &:hover, &:active {
        background-color: $medium-orange;
        cursor: pointer;

        > .sort-handle {
          &:before {
            align-items: center;
            background-color: $medium-orange;
            color: $white;
            content: "\f047";
            display: flex;
            font-family: FontAwesome;
            padding: 5px 4px;
          }
        }

        + .shade-line-items {
          border: 1px dashed $black;
          border-left: none;

          .add-coupled-shade {
            display: none;
          }
        }
      }
    }

    .shade-line-items {
      border: 1px dashed transparent;
      border-left: none;
      display: flex; //NEW
      flex-direction: column; //NEW
      flex-grow: 1;
      //overflow: hidden; //NEW
      padding: 10px 0 10px 0;

      .expandable-block {
        display: flex;
        margin-bottom: 10px;

        .sort-handle {
          margin-left: 10px;
        }

        .expandable-body {
          align-items: stretch !important;
          display: flex !important;
          flex-wrap: wrap;
          overflow: visible;
          padding-right: 10px;
          position: relative;
          flex: 1;

          .title-bar-wrapper {
            .title-bar {
              overflow: visible;

              .summary {
                .setting-text {
                  margin-top: 7px;
                }
              }
            }
          }

          //Temporary form styles
          label {
            font-size: .9rem;
            margin-bottom: 0;
          }

          .form-control {
            padding: 3px 6px;
          }

          .content-wrapper {
            .transition-container {
              flex: 1;
            }

            .advanced-settings {
              padding: 1.25rem;
              background: rgba(0, 0, 0, 0.03);

              .title-row {
                position: relative;

                .title {
                  float: left;
                  margin-right: .5em;
                }

                .btn-toggle-settings {
                  bottom: 0;
                  color: $medium-orange;
                  font-size: .9rem;
                  position: absolute;

                  &:hover, &:active {
                    cursor: pointer;
                  }
                }

                &:after {
                  clear: both;
                }
              }

              .summary {
                cursor: pointer;

                .preview {
                  .form-group {
                    margin-bottom: 0;
                  }

                  p {
                    //display: inline-block;
                    font-size: 0.80rem;
                    margin-bottom: 0;
                    margin-right: 20px;
                    text-transform: capitalize;

                    .heading {
                      //text-transform: uppercase;
                    }

                    &:last-child {
                      margin-right: 0px;
                    }
                  }
                }
              }

              .assembly-info {
                p {
                  //display: inline-block;
                  font-size: 0.8rem;
                  margin-bottom: 0;
                  margin-right: 20px;
                  text-transform: capitalize;

                  .heading {
                    text-transform: uppercase;
                  }

                  &:last-child {
                    margin-right: 0px;
                  }
                }
              }

              .edit, .summary {
                &.slide-enter-active, &.slide-leave-active {
                  max-height: 1000px;
                  overflow: hidden;
                  transition-duration: 0.4s;
                  -moz-transition-duration: 0.4s;
                  -webkit-transition-duration: 0.4s;
                  -o-transition-duration: 0.4s;
                  -moz-transition-timing-function: ease-in-out;
                  -webkit-transition-timing-function: ease-in-out;
                  -o-transition-timing-function: ease-in-out;
                  transition-timing-function: ease-in-out;
                }

                &.slide-leave-active {
                  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
                  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
                  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
                  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
                }

                &.slide-enter, &.slide-leave-to {
                  max-height: 0;
                  padding: 0 10px;
                  opacity: 0;
                }
              }

              .edit {
                .form-row {
                  &.field-section-head {
                    border-bottom: 1px solid #747474;
                    margin-bottom: .5em;

                    .section-title {
                      margin-bottom: 0;

                      h3 {
                        padding-bottom: .25em;
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }

          .shade-error {
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translate(-50%, 0%);
            //display: none;

            .notice {
              background-color: $white;
              border: 1px solid $error-text-background-border;
              border-radius: 50px;
              color: $error-text-color;
              font-size: .95em;
              padding: 4px 8px;
              text-decoration: none;

              &:before {
                content: 'Error';
                display: inline;
                margin-right: 0.5em;
              }

              &:after {
                content: "\f05c";
                display: inline;
                font-family: FontAwesome;
              }

              &:hover, &:active {
                cursor: pointer;
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      @media screen and (max-width: 1540px) {
        .expandable-body {
          .title-bar-wrapper {
            .title-bar {
              .actions {
                border-left: 1px solid #d2d2d2;
                display: flex;
                flex-wrap: wrap;
                width: 50px;

                &::before {
                  color: $medium-orange;
                  content: "\f0d7";
                  display: inline;
                  font-family: FontAwesome;
                  font-size: 1.5em;
                  margin: auto;
                }

                .action-wrapper {
                  position: absolute;
                  top: 37px;
                  right: -2px;
                  width: 100px;
                  z-index: 999;

                  .link {
                    display: none;
                    border-left: none;

                    a {
                      padding: .65rem 1.25rem;
                    }
                  }
                }

                &:hover, &:active {
                  .action-wrapper {
                    background-color: #f7f7f7;
                    border: 1px solid #d2d2d2;
                    display: flex;
                    flex-direction: column;

                    .link {
                      display: flex;

                      &:hover {
                        background-color: darken(#f7f7f7, 10%);
                      }
                    }
                  }
                }
              }
            }
          }

          &.error {
            .title-bar-wrapper {
              .title-bar {
                .actions {
                  border-color: $error-text-background-border;

                  &::before {
                    color: $error-text-background-border;
                  }
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 1200px) {
        .expandable-body {
          .title-bar-wrapper {
            .title-bar {
              .summary {
                span:nth-of-type(1n+4) {
                  display: none;
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 950px) {
        .expandable-body {
          .title-bar-wrapper {
            .title-bar {
              .summary {
                span:nth-of-type(1) {
                  display: none;
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 800px) {
        .expandable-body {
          .title-bar-wrapper {
            .title-bar {
              .summary {
                span:nth-of-type(2) {
                  display: none;
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 640px) {
        .expandable-body {
          .title-bar-wrapper {
            .title-bar {
              .summary {
                span {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}


//
// Estimator Shade Unit Configs (Modal)
//
.estimator-config-list {
  max-height: 500px;
  overflow-y: auto;

  .expandable-block {
    .expandable-body {
      .content-wrapper {
        .content-block {
          .form-row {
            &.field-section-head {
              border-bottom: 1px solid #d2d2d2;
              margin-bottom: .25rem;
            }

            &.field-section {
              margin-bottom: .5em;
            }

            .form-group {
              margin-bottom: 0;
              white-space: nowrap;
            }

            p {
              font-size: .9em;
              margin-bottom: 0;
            }

            .field-name {
              font-weight: $font-weight-bold;
            }

            .field-value {
              //
            }
          }
        }
      }
    }
  }
}


//
// Additional Items
//
#additional-items,
#motorized-parts {
  .additional-item-container {

    table {
      &.additional-items-table {
        thead {
          th {
            border-top: none;
            border-bottom: 2px solid #747474;
            white-space: nowrap;
          }

          th {
            &.part-id {
              min-width: 275px;
            }

            &.part-color-id {
              min-width: 125px;
            }

            &.part-uom {
              min-width: 100px;
            }

            &.part-quantity {
              min-width: 125px;
            }

            &.part-unit-cost {
              min-width: 100px;
            }

            &.part-total-cost {
              min-width: 100px;
            }

            &.part-actions {
              min-width: 125px;
            }
          }
        }

        tbody {
          border: none;
          tr {
            td {
              border-bottom: 1px solid #d2d2d2;
              white-space: nowrap;
            }
          }
        }
      }

      &.order-item-addition-cuts {
        margin-left: 10%;
        min-width: 500px;
        max-width: 750px;

        thead {
          th {
            font-size: .9em;
            border-bottom: 1px solid #747474;
            padding: 7px 14px 7px 7px;
          }
        }

        tbody {
          font-size: .9em;

          td {
            padding: 7px 14px 7px 7px;

            .form-control {
              font-size: .9rem;
            }
          }
        }
      }
    }
  }
}

//
// Technical Data
//
.technical-data {
  table.order-items,
  table.top-options,
  table.option-grid {
    tr {
      border-bottom: 1px solid #d2d2d2;

      &:last-child {
        border-bottom: inherit;
      }
    }

    th {
      padding: 5px 5px;
      white-space: normal;
    }

    td {
      padding: 2px 5px;
      white-space: nowrap;

    }
  }

  table.top-options,
  table.option-grid {
    th {
      color: $black;
      border-bottom: 2px solid #747474;
      font-size: .8rem;
    }

    td {
      font-size: .75rem;
    }
  }
}
