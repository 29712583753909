//
// Part List (part listing page)
//
.part-list-container {
  .additional-content {
    padding-left: 0;
  }

  .part-list {
    .part-record {
      margin-bottom: 20px;
      padding-bottom: 0px;

      .ui-sortable-handle {
        //padding-left: 0;
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }

      p {
        border: 1px solid $form-input-border-color;
        //line-height: 1.9rem;
        padding: 4px 0px 4px 33px;
        margin-bottom: 0px;

        &.part-number {
          padding-left: 10px;
        }
      }

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    .part-list {
      .part-record {
        p {
          margin-bottom: 10px;
        }
      }
    }
  }
}


//
// Product Models, Product Model Parts, and Model Part Dependencies
//
.product-model-list-container {
  .product-model-list {
    .product-model-record {
      border-bottom: 1px solid $border-color-light;
      margin-bottom: 20px;
      padding-bottom: 20px;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 20px;
      }
    }
  }
}

.model-parts-container {
  .model-part-list {
    margin-bottom: 0;

    .model-part-item {

    }
  }
}

.product-model-part-dependency-container {
  h2 {
    padding-bottom: 10px;
  }
  .model-part-dependency-list {
    .model-part-dependency-item {
      .form-group {
        margin-bottom: 10px;
      }
    }
  }
}


//
// Motors
//
.motor-list-container {
  .motor-list {
    .motor-record {
      border-bottom: 1px solid $border-color-light;
      margin-bottom: 20px;
      padding-bottom: 20px;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 20px;
      }
    }
  }
}


//
// Tube Materials
//
.tube-material-list-container {
  .tube-material-list {
    .tube-material-record {
      border-bottom: 1px solid $border-color-light;
      margin-bottom: 20px;
      padding-bottom: 20px;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 20px;
      }
    }
  }
}
