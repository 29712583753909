.tooltip-wrapper {
  display: inline-block;

  .btn[disabled] {
    pointer-events: none; /* don't let button block mouse events from reaching wrapper */
  }

  &.disabled {
    cursor: not-allowed;
  }
}
