.field-section {
  padding-bottom: 10px;
}

.form-group {
  margin-bottom: 20px;
}

.form-row {
  &.heading {
    .form-group {
      margin-bottom: 0;
    }
  }
}

label {
  color: $form-label-text-color;
  font-size: 15px;
  font-weight: $font-weight-light;
  line-height: 20px;
  margin-bottom: 5px;
  width: 100%;

  .label-tooltip {
    float: right !important;

    &:after {
      content: '\f05a';
      font-family: FontAwesome;
      color: $gray-700;
      padding: 0 5px;
    }
  }
}

label.checkbox-label {
  width: auto;
}



button {
  &:disabled {
    cursor: not-allowed;
  }
}

.form-control {
  border: 1px solid $form-input-border-color;
  color: $form-input-text-color;
  font-size: 1.0rem;
  font-weight: $font-weight-normal;
  height: auto;
  line-height: 19px;
  padding: 5px 10px;

  &:focus {
    border-color: $form-input-border-color;
    box-shadow: none;
  }
}

.form-control::-webkit-input-placeholder {
  font-style: italic;
  opacity: .75;
}
.form-control::-moz-placeholder {
  font-style: italic;
  opacity: .75;
}
.form-control:-ms-input-placeholder {
  font-style: italic;
  opacity: .75;
}
.form-control::-ms-input-placeholder {
  font-style: italic;
  opacity: .75;
}
.form-control::placeholder {
  font-style: italic;
  opacity: .75;
}

.form-row {
  &.no-results {
    .form-group {
      background-color: rgba(0, 0, 0, 0.03);
      border: 1px solid rgba(0, 0, 0, 0.125);
      text-align: center;

      p {
        margin: .5rem 0;
      }
    }
  }
}

//
// File Upload Container
//
.file-upload-container {
  outline: 2px dashed $border-color-medium; /* the dash box */
  outline-offset: -10px;
  background-color: rgba(0, 0, 0, 0.03);;
  padding: 20px 20px;
  //min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;

  &.error {
    background-color: #f2dfda;
    outline: 2px dashed $error-text-background-border; /* the dash box */

    .invalid-feedback {
      color: #633025;
      display: block;
      font-size: 90%;
    }
  }

  .saving-message {
    color: #155724;
  }

  .field-container {
    label {
      font-weight: 700;
      cursor: pointer;
    }

    .form-control-file {
      &.vue-file-input {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
      }
    }
  }

  &.has-attachment {
    outline: none;
    background-color: #fff;
    padding: 0;
    //min-height: 200px; /* minimum height */
    position: relative;
    cursor: default;

    .attachment-details {
      label {
        display: block;
        font-weight: 700;
        cursor: pointer;
      }

      .file-data {
        display: inline-block;
        width: 100%;

        .file-link, .remove-attachment {
          float: left;
          width: 50%;
        }

        .remove-attachment {
          text-align: right;
        }

        &:after {
          clear: both;
        }
      }
    }
  }
}
