/*
 * Generic List Containers
 */
.record-list, .record-list-toggle {
  .record-line-item {
    border-bottom: 1px solid $border-color-light;
    margin-bottom: 20px;
    padding-bottom: 20px;

    &.last {

    }

    h2 {
      color: #4d4d4d;
      margin-bottom: 0;
    }

    h3 {
      color: #4d4d4d;
      font-size: 15px;
      line-height: 20px;
      font-weight: $font-weight-light;
      text-transform: uppercase;
    }

    .row {
      margin-bottom: 0;
    }

    .additional-content {
      p {
        margin-bottom: 0;
      }
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;

      p {
        margin-bottom: 0;
      }
    }

    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}


/*
 * Generic Record List Structure/Format ()
 */
.record-list-items {
  .item {
    border-bottom: 1px solid $border-color-light;
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative;

    &.last {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  [class~='item']:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

//
// Expandable Content/Form Block with title bar
//
.expandable-block {
  margin-bottom: 1.5rem;
  display: flex;
  flex-wrap: nowrap;

  &.is-sortable {
    cursor: grab;
  }

  &.not-sortable {
    .sort-handle {
      display: none;
    }

    .expandable-body {
      .sort-handle {
        display: none;
      }
    }
  }

  .sort-handle {
    background-color: $medium-orange;
    display: flex;
    width: 20px;

    &:before {
      align-items: center;
      background-color: $medium-orange;
      color: $white;
      content: "\f047";
      display: flex;
      font-family: FontAwesome;
      padding: 5px 4px;
    }

    &:hover, &:active {
      background-color: $dk-orange;
    }
  }

  .expandable-body {
    align-items: stretch !important;
    display: flex !important;
    flex: 1;
    flex-wrap: wrap;
    margin-bottom: 0;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;

    .title-bar-wrapper {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;

      .title-bar {
        background-color: rgba(0, 0, 0, 0.03);
        border: 1px solid rgba(0, 0, 0, 0.125);
        display: flex;
        flex: 1;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: hidden;
        position: relative;

        .summary {
          align-self: center;
          display: flex;
          flex-wrap: nowrap;
          margin-right: auto;
          min-width: 0;
          overflow: hidden;
          padding: .65rem 1.25rem;
          text-overflow: ellipsis;
          white-space: nowrap;

          h2 {
            display: inline-block;

            .hint {
              font-weight: $font-weight-light;
              min-width: 0;
            }
          }

          .setting-text {
            display: inline-block;
            font-size: 0.9em;
            line-height: 12px;
            margin-left: 0.5em;
            margin-top: 8px;
            min-width: 0;
            overflow: hidden;
            text-transform: capitalize;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:before {
              content: "|";
              margin-right: 0.5em;
            }
          }

          .no-results {
            font-size: 0.9em;
            margin-left: 0.5em;
            margin-bottom: 0;
            min-width: 0;
            width: 100%;
            white-space: normal;
          }

          span:first-of-type {
            &:before {
              content: "";
            }
          }
        }

        .actions {
          cursor: auto;
          display: flex;
          flex-wrap: nowrap;
          //flex: 1;
          //min-width: 0;

          .link {
            border-left: 1px solid #d2d2d2;
            float: left;
            white-space: nowrap;

            a {
              color: $medium-orange;
              cursor: pointer;
              display: inline-block;
              font-weight: 700;
              text-decoration: none;
              padding: 0.65rem .85rem;

              &:before {
                display: inline;
                font-weight: $font-weight-bold;
                margin-right: 0.5em;
              }

              &:after {
                display: inline;
                font-family: FontAwesome;
                font-size: 1.25em;
                font-weight: $font-weight-light;
              }
            }

            &.edit {
              a {
                &:before {
                  content: "Edit";
                }
                &:after {
                  content: "\f044";
                }
              }
            }

            &.show {
              a {
                &:before {
                  content: "Show Details";
                }
                &:after {
                  content: "\f06e";
                }
              }
            }

            &.minimize {
              a {
                &:before {
                  content: "Hide";
                }
                &:after {
                  content: "\f070";
                }
              }
            }

            &.save {
              a {
                &:before {
                  content: "Save";
                }
                &:after {
                  content: "\f0c7";
                }
              }
            }

            &.send-email {
              a {
                &:before {
                  content: "Test Email";
                }
                &:after {
                  content: "\f1d9";
                }
              }
            }

            &.clone {
              a {
                &:before {
                  content: "Copy";
                }
                &:after {
                  content: "\f24d";
                }
              }
            }

            &.delete {
              a {
                &:before {
                  content: "Delete";
                }
                &:after {
                  content: "\f014";
                }
              }
            }

            &.error {
              a {
                color: $error-text-background-border;

                &:before {
                  content: "Error";
                }
                &:after {
                  content: "\f05c";
                }
              }

              &.assembly-error {
                a {
                  &:before {
                    content: "Warning";
                  }
                  &:after {
                    content: "\f071";
                  }
                }
              }
            }

            &:first-child {
              border-left: 0;
            }
          }
        }
      }
    }

    .content-wrapper {
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-top: none;
      border-bottom: 0;
      overflow: hidden;
      width: 100%;

      .transition-container {
        .content-block {
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
          padding: 1.25rem;

          .form-row {
            &:last-child {
              .form-group:not(.keep-margin) {
                margin-bottom: 0;
              }
            }
          }
        }

        &.slide-enter-active, &.slide-leave-active {
          max-height: 1000px;
          overflow: hidden;
          transition-duration: 0.4s;
          -moz-transition-duration: 0.4s;
          -webkit-transition-duration: 0.4s;
          -o-transition-duration: 0.4s;
          -moz-transition-timing-function: ease-in-out;
          -webkit-transition-timing-function: ease-in-out;
          -o-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
        }

        &.slide-leave-active {
          -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
          -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
          -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
          transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        }

        &.slide-enter, &.slide-leave-to {
          max-height: 0;
          padding: 0 10px;
          opacity: 0;
        }
      }
    }

    &.no-results {
      .content-wrapper {
        .title-bar {
          .summary {
            width: 100%;

            p {
              margin-bottom: 0;
              text-align: center;
            }
          }
        }
      }
    }

    &.error {
      .title-bar-wrapper {
        border: 1px solid $error-text-background-border;

        .title-bar {
          background-color: $error-text-background-highlight;

          .summary {
            h2 {
              color: $black;
            }

            .setting-text {
              //color: $form-label-text-color;
              color: $black;
            }
          }

          .actions {
            .link {
              border-left: 1px solid $error-text-background-border;

              a {
                color: $form-label-text-color;
                //color: $black;
              }

              &.error {
                a {
                  color: $error-text-background-border;
                }
              }
            }
          }
        }
      }

      .content-wrapper {
        border: 1px solid $error-text-background-border;
        border-top: none;
      }
    }

    &.maximized {}

    &.minimized {
      .content-wrapper {
        border: none;

        .content-block {
          display: none;
        }
      }
    }
  }

  &.small {
    .expandable-body {
      .title-bar-wrapper {
        .title-bar {
          .summary {
            padding: .4rem 1rem;

            h2 {
              font-size: 1.3em;
            }

            .setting-text {
              font-size: 1em;
            }
          }

          .actions {
            font-size: .9em;

            .link {
              //padding: 1em 1em;
            }
          }
        }
      }

      .content-wrapper {
        .content-block {
          font-size: .9rem;
          font-weight: $font-weight-normal;
        }
      }
    }
  }

  &.medium {
    .expandable-body {
      .title-bar-wrapper {
        .title-bar {
          .summary {
            padding: .4rem 1rem;

            h2 {
              font-size: 1.1em;
            }

            .setting-text {
              font-size: .85em;
            }
          }

          .actions {
            font-size: .9em;

            .link {
              padding: .6rem 1rem .4rem 1rem;
            }
          }
        }
      }

      .content-wrapper {
        .content-block {

        }
      }
    }
  }
}

.child-item-list {
  .content-block {
    margin-left: 1.5rem;

    .form-group {
      margin-bottom: .5rem;
    }

    .toggle-list {
      margin: .25rem 0;

      .toggle-list-link {
        font-size: 1.0rem;
        text-decoration: none;
      }
    }

    .list-items {
      .list-item {
        margin-bottom: .5rem;
        width: 100%;

        .title-bar-wrapper {
          display: flex;
          flex-wrap: nowrap;
          width: 100%;

          .title-bar {
            background-color: rgba(0, 0, 0, 0.03);
            border: 1px solid rgba(0, 0, 0, 0.125);
            display: flex;
            flex: 1;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: hidden;
            position: relative;

            .summary {
              align-self: center;
              display: flex;
              flex-wrap: nowrap;
              margin-right: auto;
              min-width: 0;
              overflow: hidden;
              padding-right: 1rem;
              text-overflow: ellipsis;
              white-space: nowrap;

              .title {
                display: inline-block;
                font-size: .9rem;
                margin-bottom: 0;
                padding: 0.5rem .5rem .5rem .75rem;

                .hint {
                  font-weight: $font-weight-light;
                  min-width: 0;
                }
              }

              .setting-text {
                align-self: center;
                display: inline-block;
                font-size: 0.9em;
                min-width: 0;
                overflow: hidden;
                padding: 0.5rem .5rem .5rem .75rem;
                text-transform: capitalize;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 1.5rem;
      }
    }
  }
}

//
// Sortable Input Row Container
//
.field-row-wrapper {
  .field-row-list {
    .field-row-block {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 20px;

      .sort-handle {
        background-color: $medium-orange;
        display: flex;
        width: 20px;

        &:before {
          align-items: center;
          background-color: $medium-orange;
          color: $white;
          content: "\f047";
          display: flex;
          font-family: FontAwesome;
          padding: 5px 4px;
        }

        &:hover, &:active {
          background-color: $dk-orange;
        }
      }

      .field-row {
        align-items: stretch !important;
        display: flex !important;
        flex: 1;
        flex-wrap: wrap;
        margin-bottom: 0;
        overflow: hidden;

        padding-left: 0;
        padding-right: 0;

        .form-group {
          margin-bottom: 0;
        }


      }
    }
  }
}
