.fabric-manufacturer-list {
  .fabric-manufacturer {

  }

  .additional-content {
    padding-left: 0;
  }

  .fabric-list {
    padding-bottom: 10px;

    .fabric-record {

      .name-column {
        margin-bottom: 20px;

        p {
          border: 1px solid $form-input-border-color;
          border-left: 0;
          height: 100%;
          line-height: 1.9rem;
          margin-bottom: 0;
          padding-left: 33px;
        }
      }
    }
  }
}