.card {
  border: 1px solid #d2d2d2;
}

.card-header {
  padding: 0 1.25rem;

  h1 {
    padding: .70rem 0;
    font-size: 16px;
  }

  .header-links {
    display: flex;
    flex-wrap: nowrap;
    justify-content: right;

    .header-link-wrapper {
      position: relative;

      .link {
        border-left: 1px solid #d2d2d2;
        float: left;
        padding: .75rem 1.25rem;

        a {
          color: #ec881e;
          font-size: 14px;
          font-family: inherit;
          font-weight: $font-weight-bold;
          line-height: 20px;
          text-decoration: none;

          &:hover {
            color: #d0700a;
            cursor: pointer;
          }

          i {
            &.fa {
              padding-left: 3px;
            }
          }
        }
      }
    }
  }

  //
  // Header Links minimized dropdown
  //
  @media screen and (max-width: 1045px) {
    .header-links {
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      padding: 0.40rem 0;
      width: 50px;

      &::before {
        color: $medium-orange;
        content: "\f0d7";
        display: inline;
        font-family: FontAwesome;
        font-size: 1.5em;
      }

      .header-link-wrapper {
        position: absolute;
        right: 0px;
        top: 45px;
        z-index: 999;

        .link {
          border-left: none;
          display: none;
          padding: 0 0;

          div {
            display: flex;
          }

          a {
            padding: .7rem 1.5rem;
            width: 100%;
          }
        }
      }

      &:hover, &:active {
        .header-link-wrapper {
          background-color: #f7f7f7;
          border: 1px solid #d2d2d2;
          display: flex;
          flex-direction: column;

          .link {
            display: flex;

            &:hover {
              background-color: darken(#f7f7f7, 10%);
            }
          }
        }
      }
    }
  }

  .row {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.card-body {
  .row {
    //margin-bottom: 15px;
  }

  .record-list-container {
    .record {
      border-bottom: 1px solid $border-color-light;
      margin-bottom: 20px;
      padding-bottom: 20px;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .card {
    margin-bottom: 30px;
  }
}
