table {

  &.reports-table {
    tr.line-item-data {
      td {
        border-bottom: 0;
      }
    }

    tr.line-item-notes {
      border-bottom: 1px solid $border-color-light;

      td {
        .note-heading {
          font-weight: bold;
          margin-bottom: 0;
        }

        .note-text {

        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }


    //
    //Row format - single line
    //
    &.single-row {
      //Defaults plus anything below...

    }

    //
    // Square format (8 columns - 4 headings / 4 values)
    //
    &.square {
      td {
        padding: 10px 20px 5px 0;
      }

      tr.line-item-data {
        border-bottom: 1px solid $border-color-light;

        td {

          &.field-name {
            font-weight: bold;
          }
        }
      }

      tr.line-item-notes {
        border-bottom: 2px solid $border-color-light;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
