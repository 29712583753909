.proposal-form-wrapper {

  .form-control {
    background-color: #ebffed;

    &:disabled {
      background-color: #e9ecef;
    }

    &.is-invalid {
      color: $error-text-color;
      background-color: $error-text-background-highlight;
    }
  }

  p.text-w-line-break {
    white-space: pre-line;
  }

  table {
    margin-top: 10px;
    margin-bottom: 0;

    thead {
      th {
        border-bottom-width: 1px;

        &.no-bottom-border {
          border-bottom-width: 0;
        }
      }
    }

    th, td {
      padding: 10px 25px 10px 0;
    }
  }

  .proposal-form {

    .inline-input {
      margin: 5px 0;
      label {
        padding-left: 0;
        min-width: 150px;
      }
    }

    .proposal-form-section {
      margin-bottom: 0px;
    }

    .section-heading {
      padding: 10px 15px;
      &.bg-gray {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }

    table {
      td, th {
        padding-left: 5px;
      }

      &.order-summary-table {
        thead {
          &.section-spacer {
            tr {
              th {
                padding-top: 30px;
              }
            }
          }
        }

        tbody {
          td {
            padding: 5px 25px 5px 5px;
          }

          &:nth-child(odd) {
            tr {
              td {
                background-color: rgba(0, 0, 0, 0.05);
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
              }
            }
          }

          &:hover {
            color: #212529;
            background-color: rgba(0, 0, 0, 0.075);
          }

          &.no-bg-color {
            tr {
              td {
                background-color: transparent;
              }
            }
          }

          &.hover-bg-off {
            &:hover {
              background-color: transparent;
            }
          }

          &.total-row {
            border-top: none;

            tr:last-of-type {
              font-weight: $font-weight-bold;

              td {
                border-bottom: none;
              }
            }
          }
        }
      }

      &.order-summary-table-short-form {
        tbody {
          &.total-row {
            border-top: none;

            td {
              padding: 2px 25px 2px 5px;
            }

            tr:last-of-type {
              font-weight: $font-weight-bold;

              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }

    .fine-print {
      font-size: 0.8rem;
    }

    .signature-block-wrapper {
      h1 {
        clear: both;
        float: none;
        padding: 0.25em 0;
      }

      .signature-block {
        .signature {
          h1 {
            font-family: 'Yellowtail', cursive;
            text-transform: none;
            font-size: 24px;
          }
        }

        .signature-field-name {
          margin-top: 0.25em;
        }

        .input-section {
          height: 60px;
        }
      }
    }

    .file-upload-container {
      .upload-attachment {
        .field-container {
          label {
            display: block;
            text-align: center;
          }

          p {
            text-align: center;
            margin: .25rem 0 .25rem 0;
          }
        }
      }
    }
  }
}
