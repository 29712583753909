//Headings
h1 {
  color: #4d4d4d;
  float: left;
  font-size: 18px;
  font-weight: $font-weight-bold;
  line-height: 23px;
  margin-bottom: 0;
  padding: .75rem 0;
  text-transform: uppercase;
}

h2 {
  color: #4d4d4d;
  font-size: 17px;
  line-height: 22px;
  margin-bottom: 0;
}

h3 {
  color: #4d4d4d;
  font-size: 14px;
  line-height: 19px;
  font-weight: $font-weight-light;
  text-transform: uppercase;
}

h4 {
  color: #4d4d4d;
  font-size: 12px;
  line-height: 17px;
  font-weight: $font-weight-normal;
}

.capitalize-first {
  text-transform: capitalize;
}

//
// Headings + Borders
//
.heading {
  margin-bottom: 20px;

  h2 {
    padding-bottom: 10px;
  }

  h3 {
    padding-bottom: 10px;
  }

  &.no-btm-mar {
    margin-bottom: 0;
  }

  &.w-bdr-btm {
    border-bottom: 4px solid #747474;

    &.bdr-xs {
      border-bottom: 1px solid #747474;
    }

    &.bdr-sm {
      border-bottom: 2px solid #747474;
    }
    &.bdr-md {
      border-bottom: 3px solid #747474;
    }
    &.bdr-lg {
      border-bottom: 4px solid #747474;
    }
  }
}
