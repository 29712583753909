/*
 * Viewport Container
 */
.viewport {
  display: flex;
  width: 100%;
  align-items: stretch;
}

/*
 * Main Content Container
 */
#content {
  min-height: 100vh;
  padding-left: 80px;
  transition: all 0.3s;
  width: 100%;
}

.container-fluid {
  padding: 25px 25px;
}

.row {
  //margin-top: 30px;
  margin-bottom: 30px;

  &.no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

/*
 * Credit Hold Page
 */
.credit-hold {
  height: calc(100vh - 50px);

  .title {
    font-size: 24px;
    margin-bottom: .5em;
    line-height: 1em;
  }
}

/*
 * VuejS - Hide Elements until everthing is fully loaded (modify to add a loading icon?)
 */
[v-cloak] {
  display:none;
}


/*
 * Misc
 */
.no-margin-btm {
  margin-bottom: 0 !important;
}

.disabled-overlay {
  background: rgba(0,0,0,0.15);
  z-index: 9999999;

  .form-control, .btn {
    pointer-events: none;
  }
}

/*
 * Loading Icon
 */
#app {
  .loading {
    display: none;
  }

  &.wait {
    position: absolute;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    opacity: .5;
    pointer-events: none;

    .loading {
      display: block;
      opacity: 1;
      position: fixed;
      left: 50%;
      top: 50%;
      z-index: 9999;

      .spinner-wrapper {
        border: 2px solid #000;
        padding: 25px;
        border-radius: 5px;
        background-color: #fff;
      }
    }
  }
}


