/* Lutek: Fixes to Modal Windows */
/* Lutek: Added deleteDealerAccountModal Fixes */
#withdrawOrderModal.modal, #dealerCreditHoldModal.modal, #cancelOrderModal.modal, #deleteDealerAccountModal.modal {
	pointer-events: none !important;
}
#withdrawOrderModal.modal .modal-content, #dealerCreditHoldModal.modal .modal-content, #cancelOrderModal.modal .modal-content, #deleteDealerAccountModal.modal .modal-content {
	pointer-events: none !important;
}
#withdrawOrderModal.modal.show, #dealerCreditHoldModal.modal.show, #cancelOrderModal.modal.show, #deleteDealerAccountModal.modal.show {
	pointer-events: auto !important;
}
#withdrawOrderModal.modal.show .modal-content, #dealerCreditHoldModal.modal.show .modal-content, #cancelOrderModal.modal.show .modal-content, #deleteDealerAccountModal.modal.show .modal-content {
	pointer-events: auto !important;
}

.modal {
  width: 300px !important;
  background: #FFFFFF00 !important;
  box-shadow: none !important;
  overflow: auto !important;
  display: flex;
  flex-direction: column !important;
  margin: auto !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.modal-dialog {
  margin: 200px auto !important;
  width: 50% !important;
  min-width: 320px !important;
  display: flex !important;
  flex-direction: column !important;
  position: fixed !important;
  top: 1px !important;
  left: 1px !important;
  right: 1px !important;
  box-shadow: 2px 2px 10px 2px !important;
  overflow: auto !important;
}

.modal-content {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  outline: 0 !important;
  max-height: 500px !important;
}

.modal-header {
  border-bottom: 1px solid #eeeeee !important;
  color: #ec881e !important;
  justify-content: space-between !important;
  padding: 0 15px 15px 15px !important;
  align-content: flex-start !important;
  flex-direction: row !important;
  display: flex !important;
}

.modal-title {
  margin-bottom: 0 !important;
  line-height: 1.5 !important;
  width: 300px !important;
}

.close {
  background-color: transparent !important;
  font-size: 1.35rem !important;
  font-weight: 700 !important;
  line-height: 1.35rem !important;
  color: #000 !important;
  text-shadow: 0 1px 0 #fff !important;
  opacity: .5 !important;
  border: 0 !important;
  width: 20px !important;
  display: block !important;
  margin-top: -10px !important;
}

.modal-body {
  position: relative !important;
  flex: 1 1 auto !important;
  padding: 15px !important;
}

.modal-footer {
  padding: 15px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  padding: 0.75rem !important;
  border-top: 1px solid #dee2e6 !important;
  border-bottom-right-radius: calc(0.3rem - 1px) !important;
  border-bottom-left-radius: calc(0.3rem - 1px) !important;
}
.modal-footer .btn {
  margin-left: 15px !important;
}
.modal-footer form {
  margin-bottom: 0 !important;
}

.modal.fade .modal-dialog {
    transform: inherit !important;
}
.modal-backdrop.show {
    opacity: 0 !important;
}

.modal-header .close {
    margin: 0 !important;
}
.modal-title {
    margin-top: 10px !important;
}