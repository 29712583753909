/*
 * Buttons
 */
.btn {
  border: none;
  font-size: 1rem;
  line-height: 19px;
  text-transform: uppercase;
  padding: 6px 10px;

  &.btn-primary {
    color: $white;
    background-color: $orange-button;

    &:hover {
      background-color: $orange-button-hover;
    }
  }

  &.btn-secondary {
    color: $white;
    background-color: $tan-button;

    &:hover {
      background-color: $tan-button-hover;
    }
  }

  &.btn-save {
    color: $white;
    background-color: $success;

    &:hover {
      color: $white;
      background-color: darken($success, 10%);
    }
  }

  &.btn-danger,
  &.btn-delete {
    color: $white;
    background-color: $delete-button;

    &:hover {
      background-color: $delete-button-hover;
    }
  }

  &.btn-search {
    &:before {
      content: '\f002';
      font-family: 'FontAwesome';
    }
  }

  &.btn-full-width {
    width: 100%;
  }

  //
  // Icon only buttons
  //
  &.icon-only {
    &.btn-copy,
    &.btn-clone {
      &:after {
        content: '\F24D';
        font-family: 'FontAwesome';
      }
    }

    &.btn-delete {
      &:after {
        content: '\f014';
        font-family: 'FontAwesome';
      }
    }

    &.btn-edit {
      &:after {
        content: '\F044';
        font-family: 'FontAwesome';
      }
    }

    &.btn-save {
      &:after {
        content: '\f0c7';
        font-family: 'FontAwesome';
      }
    }
  }
}



/*
 * Button Group
 */
.btn-group {
  > .btn {
    margin-right: 2px;
  }
}

/*
 * Styles Links
 */
.link {
  &.with-icon {
    &:after {
      margin-left: 4px;
    }

    &.link-copy,
    &.link-clone {
      &:after {
        content: '\F24D';
        font-family: 'FontAwesome';
      }
    }

    &.link-edit {
      &:after {
        content: '\F044';
        font-family: 'FontAwesome';
      }
    }

    &.link-view {
      &:after {
        content: '\f06e';
        font-family: 'FontAwesome';
      }
    }
  }
}
